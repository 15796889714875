import React from "react";
import qbank from '../qbank';
import SubTopicButton from "./SubTopicButton";
import { v4 as uuidv4 } from 'uuid';


function TopicSection(props) {
    var topic_qns =[];
    topic_qns = qbank.filter( function (qns){
        return qns.topic === props.topic;
        });

    var sub_topic_list = topic_qns.map(a => a.sub_topic);

    var unique_sub_topic_list = [...new Set(sub_topic_list)];
    unique_sub_topic_list.sort();

    return (
        <p>
            <h3 className="heading">{props.topic} </h3>
            <p>{unique_sub_topic_list.map((item) => <SubTopicButton key={uuidv4()} sub_topic={item} />)}</p>
        </p>);
    
}

export default TopicSection;