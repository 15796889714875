import React, {useState} from "react";
import qbank from '../qbank';
import { v4 as uuidv4 } from 'uuid';
import Question from './Question';

function YearButton(props) {
    var year_qns= [];

    year_qns = qbank.filter( function (qns){
        return qns.year === props.year;
        });

        function createQns(qns){
            return <Question 
            key={uuidv4()}
            subTopic={qns.sub_topic}
            qImage ={qns.qns_image}
            aImage ={qns.ans_image} />
            ;
        }

        const [isClicked, setClick]= useState(false);
        function handleClick(){
            setClick(!isClicked);                
        }


    return (
        <header>
            <button onClick={handleClick} className="button">{props.year} </button>
            <p></p>
            {isClicked &&  year_qns.map(createQns)}
        </header>
        
        );
    
}

export default YearButton;