import React, {useState} from "react";
import qbank from '../qbank';
import { v4 as uuidv4 } from 'uuid';
import Question from './Question';



function SubTopicButton(props) {
    var sub_topic_qns= [];

    sub_topic_qns = qbank.filter( function (qns){
        return qns.sub_topic === props.sub_topic;
        });
    
        function createQns(qns){
            return <Question 
            key={uuidv4()}
            qImage ={qns.qns_image}
            aImage ={qns.ans_image} />
            ;
        }

    const [isClicked, setClick]= useState(false);
    function handleClick(){
        setClick(!isClicked);                
    }
    
    return (
        <p>
            <button onClick={handleClick} className="button">{props.sub_topic} </button>
            <p></p>
            {isClicked &&  sub_topic_qns.map(createQns)}
        </p>);
    
}

export default SubTopicButton;