const qbank = [

  {year: "2008" , paper: "1" , qns_num: "1", qns_image: "2008_1_1", ans_image: "2008_1_1_A", topic: "Trigo", sub_topic: "Trigo proof"},
  {year: "2008" , paper: "1" , qns_num: "2", qns_image: "2008_1_2", ans_image: "2008_1_2_A", topic: "Indices", sub_topic: "Simultaneous equation"},
  {year: "2008" , paper: "1" , qns_num: "3", qns_image: "2008_1_3", ans_image: "2008_1_3_A", topic: "Matrix", sub_topic: "Matrix"},
  {year: "2008" , paper: "1" , qns_num: "4", qns_image: "2008_1_4", ans_image: "2008_1_4_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
  {year: "2008" , paper: "1" , qns_num: "5", qns_image: "2008_1_5", ans_image: "2008_1_5_A", topic: "Partial fractions", sub_topic: "Differentiation"},
  {year: "2008" , paper: "1" , qns_num: "6", qns_image: "2008_1_6", ans_image: "2008_1_6_A", topic: "Kinematics", sub_topic: "Differentiation Integration"},
  {year: "2008" , paper: "1" , qns_num: "7", qns_image: "2008_1_7", ans_image: "2008_1_7_A", topic: "Differentiation", sub_topic: "Differentiation of trigo"},
  {year: "2008" , paper: "1" , qns_num: "8", qns_image: "2008_1_8", ans_image: "2008_1_8_A", topic: "Trigo", sub_topic: "Trigo proof"},
  {year: "2008" , paper: "1" , qns_num: "9", qns_image: "2008_1_9", ans_image: "2008_1_9_A", topic: "Simultaneous equation", sub_topic: "Word problem"},
  {year: "2008" , paper: "1" , qns_num: "10", qns_image: "2008_1_10", ans_image: "2008_1_10_A", topic: "Discriminant", sub_topic: "Always positive"},
  {year: "2008" , paper: "1" , qns_num: "11", qns_image: "2008_1_11", ans_image: "2008_1_11_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
  {year: "2008" , paper: "1" , qns_num: "12", qns_image: "2008_1_12", ans_image: "2008_1_12_A", topic: "Linear law", sub_topic: "Graph provided"},
  {year: "2008" , paper: "1" , qns_num: "13", qns_image: "2008_1_13", ans_image: "2008_1_13_A", topic: "Differentiation", sub_topic: "Stationary value"},
  
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_1", ans_image: "2008_2_1_A", topic: "Logarithms", sub_topic: "Word problems"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_2", ans_image: "2008_2_2_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_3", ans_image: "2008_2_3_A", topic: "Trigo", sub_topic: "Trigo proof"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_4", ans_image: "2008_2_4_A", topic: "Logarithms", sub_topic: "Log equations"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_5", ans_image: "2008_2_5_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_6", ans_image: "2008_2_6_A", topic: "Geometric proof", sub_topic: "Midpoint theorem"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_7", ans_image: "2008_2_7_A", topic: "Trigo", sub_topic: "Trigo graph"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_8", ans_image: "2008_2_8_A", topic: "Differentiation", sub_topic: "Stationary points"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_9", ans_image: "2008_2_9_A", topic: "Trigo", sub_topic: "R formula"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_10", ans_image: "2008_2_10_A", topic: "Differentiation", sub_topic: "Equation of normal"},
  {year: "2008" , paper: "2" , qns_num: "1", qns_image: "2008_2_11", ans_image: "2008_2_11_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

  {year: "2009" , paper: "1" , qns_num: "1", qns_image: "2009_1_1", ans_image: "2009_1_1_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
  {year: "2009" , paper: "1" , qns_num: "2", qns_image: "2009_1_2", ans_image: "2009_1_2_A", topic: "Differentiation", sub_topic: "Increasing function"},
  {year: "2009" , paper: "1" , qns_num: "3", qns_image: "2009_1_3", ans_image: "2009_1_3_A", topic: "Surds", sub_topic: "Surds"},
  {year: "2009" , paper: "1" , qns_num: "4", qns_image: "2009_1_4", ans_image: "2009_1_4_A", topic: "Logarithms", sub_topic: "Log equations"},
  {year: "2009" , paper: "1" , qns_num: "5", qns_image: "2009_1_5", ans_image: "2009_1_5_A", topic: "Differentiation", sub_topic: "Equation of normal"},
  {year: "2009" , paper: "1" , qns_num: "6", qns_image: "2009_1_6", ans_image: "2009_1_6_A", topic: "Discriminant", sub_topic: "Discriminant"},
  {year: "2009" , paper: "1" , qns_num: "7", qns_image: "2009_1_7", ans_image: "2009_1_7_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
  {year: "2009" , paper: "1" , qns_num: "8", qns_image: "2009_1_8", ans_image: "2009_1_8_A", topic: "Trigo", sub_topic: "Trigo proof"},
  {year: "2009" , paper: "1" , qns_num: "9", qns_image: "2009_1_9", ans_image: "2009_1_9_A", topic: "Trigo", sub_topic: "Trigo graph"},
  {year: "2009" , paper: "1" , qns_num: "10", qns_image: "2009_1_10", ans_image: "2009_1_10_A", topic: "Linear law", sub_topic: "Plot graph"},
  {year: "2009" , paper: "1" , qns_num: "11", qns_image: "2009_1_11", ans_image: "2009_1_11_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
  {year: "2009" , paper: "1" , qns_num: "12", qns_image: "2009_1_12", ans_image: "2009_1_12_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
  
  {year: "2009" , paper: "2" , qns_num: "1", qns_image: "2009_2_1", ans_image: "2009_2_1_A", topic: "Trigo", sub_topic: "Trigo proof"},
  {year: "2009" , paper: "2" , qns_num: "2", qns_image: "2009_2_2", ans_image: "2009_2_2_A", topic: "Integration", sub_topic: "Partial fractions"},
  {year: "2009" , paper: "2" , qns_num: "3", qns_image: "2009_2_3", ans_image: "2009_2_3_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
  {year: "2009" , paper: "2" , qns_num: "4", qns_image: "2009_2_4", ans_image: "2009_2_4_A", topic: "Geometric proof", sub_topic: "Similarity congruency"},
  {year: "2009" , paper: "2" , qns_num: "5", qns_image: "2009_2_5", ans_image: "2009_2_5_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
  {year: "2009" , paper: "2" , qns_num: "6", qns_image: "2009_2_6", ans_image: "2009_2_6_A", topic: "Integration", sub_topic: "Area under graph"},
  {year: "2009" , paper: "2" , qns_num: "7", qns_image: "2009_2_7", ans_image: "2009_2_7_A", topic: "Modulus function", sub_topic: "Modulus function"},
  {year: "2009" , paper: "2" , qns_num: "8", qns_image: "2009_2_8", ans_image: "2009_2_8_A", topic: "Kinematics", sub_topic: "Differentiation"},
  {year: "2009" , paper: "2" , qns_num: "9", qns_image: "2009_2_9", ans_image: "2009_2_9_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
  {year: "2009" , paper: "2" , qns_num: "10", qns_image: "2009_2_10", ans_image: "2009_2_10_A", topic: "Integration", sub_topic: "Stationary points"},
  {year: "2009" , paper: "2" , qns_num: "11", qns_image: "2009_2_11", ans_image: "2009_2_11_A", topic: "Trigo", sub_topic: "R formula"},

  {year: "2010" , paper: "1" , qns_num: "1", qns_image: "2010_1_1", ans_image: "2010_1_1_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2010" , paper: "1" , qns_num: "2", qns_image: "2010_1_2", ans_image: "2010_1_2_A", topic: "Integration", sub_topic: "Integration trigo"},
{year: "2010" , paper: "1" , qns_num: "3", qns_image: "2010_1_3", ans_image: "2010_1_3_A", topic: "Quadratic Inequality", sub_topic: "Quadratic Inequality"},
{year: "2010" , paper: "1" , qns_num: "4", qns_image: "2010_1_4", ans_image: "2010_1_4_A", topic: "Differentiation", sub_topic: "Differentiation of trigo"},
{year: "2010" , paper: "1" , qns_num: "5", qns_image: "2010_1_5", ans_image: "2010_1_5_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2010" , paper: "1" , qns_num: "6", qns_image: "2010_1_6", ans_image: "2010_1_6_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2010" , paper: "1" , qns_num: "7", qns_image: "2010_1_7", ans_image: "2010_1_7_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2010" , paper: "1" , qns_num: "8", qns_image: "2010_1_8", ans_image: "2010_1_8_A", topic: "Differentiation", sub_topic: "Increasing decreasing function"},
{year: "2010" , paper: "1" , qns_num: "9", qns_image: "2010_1_9", ans_image: "2010_1_9_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2010" , paper: "1" , qns_num: "10", qns_image: "2010_1_10", ans_image: "2010_1_10_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2010" , paper: "1" , qns_num: "11", qns_image: "2010_1_11", ans_image: "2010_1_11_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2010" , paper: "1" , qns_num: "12", qns_image: "2010_1_12", ans_image: "2010_1_12_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

{year: "2010" , paper: "2" , qns_num: "1", qns_image: "2010_2_1", ans_image: "2010_2_1_A", topic: "Trigo", sub_topic: "Trigo equations"},
{year: "2010" , paper: "2" , qns_num: "2", qns_image: "2010_2_2", ans_image: "2010_2_2_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2010" , paper: "2" , qns_num: "3", qns_image: "2010_2_3", ans_image: "2010_2_3_A", topic: "Indices", sub_topic: "Simultaneous equation"},
{year: "2010" , paper: "2" , qns_num: "4", qns_image: "2010_2_4", ans_image: "2010_2_4_A", topic: "Binomial expansion", sub_topic: "General term"},
{year: "2010" , paper: "2" , qns_num: "5", qns_image: "2010_2_5", ans_image: "2010_2_5_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2010" , paper: "2" , qns_num: "6", qns_image: "2010_2_6", ans_image: "2010_2_6_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2010" , paper: "2" , qns_num: "7", qns_image: "2010_2_7", ans_image: "2010_2_7_A", topic: "Differentiation", sub_topic: "Equation of tangent"},
{year: "2010" , paper: "2" , qns_num: "8", qns_image: "2010_2_8", ans_image: "2010_2_8_A", topic: "Kinematics", sub_topic: "Integration"},
{year: "2010" , paper: "2" , qns_num: "9", qns_image: "2010_2_9", ans_image: "2010_2_9_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2010" , paper: "2" , qns_num: "10", qns_image: "2010_2_10", ans_image: "2010_2_10_A", topic: "Partial fractions", sub_topic: "Partial fractions"},
{year: "2010" , paper: "2" , qns_num: "11", qns_image: "2010_2_11", ans_image: "2010_2_11_A", topic: "Trigo", sub_topic: "R formula"},

{year: "2011" , paper: "1" , qns_num: "1", qns_image: "2011_1_1", ans_image: "2011_1_1_A", topic: "Differentiation", sub_topic: "Decreasing function"},
{year: "2011" , paper: "1" , qns_num: "2", qns_image: "2011_1_2", ans_image: "2011_1_2_A", topic: "Linear law", sub_topic: "Graph provided"},
{year: "2011" , paper: "1" , qns_num: "3", qns_image: "2011_1_3", ans_image: "2011_1_3_A", topic: "Indices", sub_topic: "Indices"},
{year: "2011" , paper: "1" , qns_num: "4", qns_image: "2011_1_4", ans_image: "2011_1_4_A", topic: "Trigo", sub_topic: "Trigo equations"},
{year: "2011" , paper: "1" , qns_num: "5", qns_image: "2011_1_5", ans_image: "2011_1_5_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2011" , paper: "1" , qns_num: "6", qns_image: "2011_1_6", ans_image: "2011_1_6_A", topic: "Simultaneous equation", sub_topic: "Word problem"},
{year: "2011" , paper: "1" , qns_num: "7", qns_image: "2011_1_7", ans_image: "2011_1_7_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2011" , paper: "1" , qns_num: "8", qns_image: "2011_1_8", ans_image: "2011_1_8_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2011" , paper: "1" , qns_num: "9", qns_image: "2011_1_9", ans_image: "2011_1_9_A", topic: "Differentiation", sub_topic: "Equation of normal"},
{year: "2011" , paper: "1" , qns_num: "10", qns_image: "2011_1_10", ans_image: "2011_1_10_A", topic: "Surds", sub_topic: "Surds"},
{year: "2011" , paper: "1" , qns_num: "11", qns_image: "2011_1_11", ans_image: "2011_1_11_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2011" , paper: "1" , qns_num: "12", qns_image: "2011_1_12", ans_image: "2011_1_12_A", topic: "Kinematics", sub_topic: "Differentiation"},
{year: "2011" , paper: "1" , qns_num: "13", qns_image: "2011_1_13", ans_image: "2011_1_13_A", topic: "Trigo", sub_topic: "R formula"},

{year: "2011" , paper: "2" , qns_num: "1", qns_image: "2011_2_1", ans_image: "2011_2_1_A", topic: "Discriminant", sub_topic: "Discriminant"},
{year: "2011" , paper: "2" , qns_num: "2", qns_image: "2011_2_2", ans_image: "2011_2_2_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2011" , paper: "2" , qns_num: "3", qns_image: "2011_2_3", ans_image: "2011_2_3_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2011" , paper: "2" , qns_num: "4", qns_image: "2011_2_4", ans_image: "2011_2_4_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2011" , paper: "2" , qns_num: "5", qns_image: "2011_2_5", ans_image: "2011_2_5_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2011" , paper: "2" , qns_num: "6", qns_image: "2011_2_6", ans_image: "2011_2_6_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2011" , paper: "2" , qns_num: "7", qns_image: "2011_2_7", ans_image: "2011_2_7_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2011" , paper: "2" , qns_num: "8", qns_image: "2011_2_8", ans_image: "2011_2_8_A", topic: "Partial fractions", sub_topic: "Integration"},
{year: "2011" , paper: "2" , qns_num: "9", qns_image: "2011_2_9", ans_image: "2011_2_9_A", topic: "Differentiation", sub_topic: "Integration"},
{year: "2011" , paper: "2" , qns_num: "10", qns_image: "2011_2_10", ans_image: "2011_2_10_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2011" , paper: "2" , qns_num: "11", qns_image: "2011_2_11", ans_image: "2011_2_11_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

{year: "2012" , paper: "1" , qns_num: "1", qns_image: "2012_1_1", ans_image: "2012_1_1_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2012" , paper: "1" , qns_num: "2", qns_image: "2012_1_2", ans_image: "2012_1_2_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2012" , paper: "1" , qns_num: "3", qns_image: "2012_1_3", ans_image: "2012_1_3_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2012" , paper: "1" , qns_num: "4", qns_image: "2012_1_4", ans_image: "2012_1_4_A", topic: "Matrix", sub_topic: "Matrix"},
{year: "2012" , paper: "1" , qns_num: "5", qns_image: "2012_1_5", ans_image: "2012_1_5_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2012" , paper: "1" , qns_num: "6", qns_image: "2012_1_6", ans_image: "2012_1_6_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2012" , paper: "1" , qns_num: "7", qns_image: "2012_1_7", ans_image: "2012_1_7_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2012" , paper: "1" , qns_num: "8", qns_image: "2012_1_8", ans_image: "2012_1_8_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2012" , paper: "1" , qns_num: "9", qns_image: "2012_1_9", ans_image: "2012_1_9_A", topic: "Kinematics", sub_topic: "Integration"},
{year: "2012" , paper: "1" , qns_num: "10", qns_image: "2012_1_10", ans_image: "2012_1_10_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2012" , paper: "1" , qns_num: "11", qns_image: "2012_1_11", ans_image: "2012_1_11_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2012" , paper: "1" , qns_num: "12", qns_image: "2012_1_12", ans_image: "2012_1_12_A", topic: "Indices", sub_topic: "Surds"},
{year: "2012" , paper: "1" , qns_num: "13", qns_image: "2012_1_13", ans_image: "2012_1_13_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

{year: "2012" , paper: "2" , qns_num: "1", qns_image: "2012_2_1", ans_image: "2012_2_1_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2012" , paper: "2" , qns_num: "2", qns_image: "2012_2_2", ans_image: "2012_2_2_A", topic: "Trigo", sub_topic: "Trigo equations"},
{year: "2012" , paper: "2" , qns_num: "3", qns_image: "2012_2_3", ans_image: "2012_2_3_A", topic: "Integration", sub_topic: "Partial fractions"},
{year: "2012" , paper: "2" , qns_num: "4", qns_image: "2012_2_4", ans_image: "2012_2_4_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2012" , paper: "2" , qns_num: "5", qns_image: "2012_2_5", ans_image: "2012_2_5_A", topic: "Differentiation", sub_topic: "Integration"},
{year: "2012" , paper: "2" , qns_num: "6", qns_image: "2012_2_6", ans_image: "2012_2_6_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2012" , paper: "2" , qns_num: "7", qns_image: "2012_2_7", ans_image: "2012_2_7_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2012" , paper: "2" , qns_num: "8", qns_image: "2012_2_8", ans_image: "2012_2_8_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2012" , paper: "2" , qns_num: "9", qns_image: "2012_2_9", ans_image: "2012_2_9_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2012" , paper: "2" , qns_num: "10", qns_image: "2012_2_10", ans_image: "2012_2_10_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2012" , paper: "2" , qns_num: "11", qns_image: "2012_2_11", ans_image: "2012_2_11_A", topic: "Differentiation", sub_topic: "Integration"},

{year: "2013" , paper: "1" , qns_num: "1", qns_image: "2013_1_1", ans_image: "2013_1_1_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2013" , paper: "1" , qns_num: "2", qns_image: "2013_1_2", ans_image: "2013_1_2_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2013" , paper: "1" , qns_num: "3", qns_image: "2013_1_3", ans_image: "2013_1_3_A", topic: "Differentiation", sub_topic: "Decreasing function"},
{year: "2013" , paper: "1" , qns_num: "4", qns_image: "2013_1_4", ans_image: "2013_1_4_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2013" , paper: "1" , qns_num: "5", qns_image: "2013_1_5", ans_image: "2013_1_5_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2013" , paper: "1" , qns_num: "6", qns_image: "2013_1_6", ans_image: "2013_1_6_A", topic: "Partial fractions", sub_topic: "Partial fractions"},
{year: "2013" , paper: "1" , qns_num: "7", qns_image: "2013_1_7", ans_image: "2013_1_7_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2013" , paper: "1" , qns_num: "8", qns_image: "2013_1_8", ans_image: "2013_1_8_A", topic: "Logarithms", sub_topic: "Word problems"},
{year: "2013" , paper: "1" , qns_num: "9", qns_image: "2013_1_9", ans_image: "2013_1_9_A", topic: "Differentiation", sub_topic: "Quadratic inequality"},
{year: "2013" , paper: "1" , qns_num: "10", qns_image: "2013_1_10", ans_image: "2013_1_10_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2013" , paper: "1" , qns_num: "11", qns_image: "2013_1_11", ans_image: "2013_1_11_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2013" , paper: "1" , qns_num: "12", qns_image: "2013_1_12", ans_image: "2013_1_12_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2013" , paper: "1" , qns_num: "13", qns_image: "2013_1_13", ans_image: "2013_1_13_A", topic: "Linear law", sub_topic: "Plot graph"},

{year: "2013" , paper: "2" , qns_num: "1", qns_image: "2013_2_1", ans_image: "2013_2_1_A", topic: "Matrix", sub_topic: "Matrix"},
{year: "2013" , paper: "2" , qns_num: "2", qns_image: "2013_2_2", ans_image: "2013_2_2_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2013" , paper: "2" , qns_num: "3", qns_image: "2013_2_3", ans_image: "2013_2_3_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2013" , paper: "2" , qns_num: "4", qns_image: "2013_2_4", ans_image: "2013_2_4_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2013" , paper: "2" , qns_num: "5", qns_image: "2013_2_5", ans_image: "2013_2_5_A", topic: "Integration", sub_topic: "Integration trigo"},
{year: "2013" , paper: "2" , qns_num: "6", qns_image: "2013_2_6", ans_image: "2013_2_6_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2013" , paper: "2" , qns_num: "7", qns_image: "2013_2_7", ans_image: "2013_2_7_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2013" , paper: "2" , qns_num: "8", qns_image: "2013_2_8", ans_image: "2013_2_8_A", topic: "Surds", sub_topic: "Indices"},
{year: "2013" , paper: "2" , qns_num: "9", qns_image: "2013_2_9", ans_image: "2013_2_9_A", topic: "Kinematics", sub_topic: "Integration"},
{year: "2013" , paper: "2" , qns_num: "10", qns_image: "2013_2_10", ans_image: "2013_2_10_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2013" , paper: "2" , qns_num: "11", qns_image: "2013_2_11", ans_image: "2013_2_11_A", topic: "Integration", sub_topic: "Area under graph"},

{year: "2014" , paper: "1" , qns_num: "1", qns_image: "2014_1_1", ans_image: "2014_1_1_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2014" , paper: "1" , qns_num: "2", qns_image: "2014_1_2", ans_image: "2014_1_2_A", topic: "Trigo", sub_topic: "Drawing triangles"},
{year: "2014" , paper: "1" , qns_num: "3", qns_image: "2014_1_3", ans_image: "2014_1_3_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2014" , paper: "1" , qns_num: "4", qns_image: "2014_1_4", ans_image: "2014_1_4_A", topic: "Partial fractions", sub_topic: "Partial fractions"},
{year: "2014" , paper: "1" , qns_num: "5", qns_image: "2014_1_5", ans_image: "2014_1_5_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2014" , paper: "1" , qns_num: "6", qns_image: "2014_1_6", ans_image: "2014_1_6_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2014" , paper: "1" , qns_num: "7", qns_image: "2014_1_7", ans_image: "2014_1_7_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2014" , paper: "1" , qns_num: "8", qns_image: "2014_1_8", ans_image: "2014_1_8_A", topic: "Integration", sub_topic: "Differentiation"},
{year: "2014" , paper: "1" , qns_num: "9", qns_image: "2014_1_9", ans_image: "2014_1_9_A", topic: "Discriminant", sub_topic: "Quadratic inequality"},
{year: "2014" , paper: "1" , qns_num: "10", qns_image: "2014_1_10", ans_image: "2014_1_10_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2014" , paper: "1" , qns_num: "11", qns_image: "2014_1_11", ans_image: "2014_1_11_A", topic: "Differentiation", sub_topic: "Equation of tangent"},
{year: "2014" , paper: "1" , qns_num: "12", qns_image: "2014_1_12", ans_image: "2014_1_12_A", topic: "Modulus function", sub_topic: "Modulus function"},

{year: "2014" , paper: "2" , qns_num: "1", qns_image: "2014_2_1", ans_image: "2014_2_1_A", topic: "Logarithms", sub_topic: "Word problems"},
{year: "2014" , paper: "2" , qns_num: "2", qns_image: "2014_2_2", ans_image: "2014_2_2_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2014" , paper: "2" , qns_num: "3", qns_image: "2014_2_3", ans_image: "2014_2_3_A", topic: "Surds", sub_topic: "Surds"},
{year: "2014" , paper: "2" , qns_num: "4", qns_image: "2014_2_4", ans_image: "2014_2_4_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2014" , paper: "2" , qns_num: "5", qns_image: "2014_2_5", ans_image: "2014_2_5_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2014" , paper: "2" , qns_num: "6", qns_image: "2014_2_6", ans_image: "2014_2_6_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2014" , paper: "2" , qns_num: "7", qns_image: "2014_2_7", ans_image: "2014_2_7_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2014" , paper: "2" , qns_num: "8", qns_image: "2014_2_8", ans_image: "2014_2_8_A", topic: "Kinematics", sub_topic: "Integration"},
{year: "2014" , paper: "2" , qns_num: "9", qns_image: "2014_2_9", ans_image: "2014_2_9_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2014" , paper: "2" , qns_num: "10", qns_image: "2014_2_10", ans_image: "2014_2_10_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2014" , paper: "2" , qns_num: "11", qns_image: "2014_2_11", ans_image: "2014_2_11_A", topic: "Integration", sub_topic: "Area under graph"},

{year: "2015" , paper: "1" , qns_num: "1", qns_image: "2015_1_1", ans_image: "2015_1_1_A", topic: "Differentiation", sub_topic: "Increasing function"},
{year: "2015" , paper: "1" , qns_num: "2", qns_image: "2015_1_2", ans_image: "2015_1_2_A", topic: "Logarithms", sub_topic: "Log graph"},
{year: "2015" , paper: "1" , qns_num: "3", qns_image: "2015_1_3", ans_image: "2015_1_3_A", topic: "Logarithms", sub_topic: "Word problems"},
{year: "2015" , paper: "1" , qns_num: "4", qns_image: "2015_1_4", ans_image: "2015_1_4_A", topic: "Discriminant", sub_topic: "Always negative"},
{year: "2015" , paper: "1" , qns_num: "5", qns_image: "2015_1_5", ans_image: "2015_1_5_A", topic: "Curve sketching", sub_topic: "Coordinate geometry"},
{year: "2015" , paper: "1" , qns_num: "6", qns_image: "2015_1_6", ans_image: "2015_1_6_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2015" , paper: "1" , qns_num: "7", qns_image: "2015_1_7", ans_image: "2015_1_7_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2015" , paper: "1" , qns_num: "8", qns_image: "2015_1_8", ans_image: "2015_1_8_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2015" , paper: "1" , qns_num: "9", qns_image: "2015_1_9", ans_image: "2015_1_9_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2015" , paper: "1" , qns_num: "10", qns_image: "2015_1_10", ans_image: "2015_1_10_A", topic: "Kinematics", sub_topic: "Integration"},
{year: "2015" , paper: "1" , qns_num: "11", qns_image: "2015_1_11", ans_image: "2015_1_11_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2015" , paper: "1" , qns_num: "12", qns_image: "2015_1_12", ans_image: "2015_1_12_A", topic: "Differentiation", sub_topic: "Integration"},

{year: "2015" , paper: "2" , qns_num: "1", qns_image: "2015_2_1", ans_image: "2015_2_1_A", topic: "Integration", sub_topic: "Integration of e"},
{year: "2015" , paper: "2" , qns_num: "2", qns_image: "2015_2_2", ans_image: "2015_2_2_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2015" , paper: "2" , qns_num: "3", qns_image: "2015_2_3", ans_image: "2015_2_3_A", topic: "Differentiation", sub_topic: "Equation of tangent"},
{year: "2015" , paper: "2" , qns_num: "4", qns_image: "2015_2_4", ans_image: "2015_2_4_A", topic: "Binomial expansion", sub_topic: "General term"},
{year: "2015" , paper: "2" , qns_num: "5", qns_image: "2015_2_5", ans_image: "2015_2_5_A", topic: "Surds", sub_topic: "Surds"},
{year: "2015" , paper: "2" , qns_num: "6", qns_image: "2015_2_6", ans_image: "2015_2_6_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2015" , paper: "2" , qns_num: "7", qns_image: "2015_2_7", ans_image: "2015_2_7_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2015" , paper: "2" , qns_num: "8", qns_image: "2015_2_8", ans_image: "2015_2_8_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2015" , paper: "2" , qns_num: "9", qns_image: "2015_2_9", ans_image: "2015_2_9_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2015" , paper: "2" , qns_num: "10", qns_image: "2015_2_10", ans_image: "2015_2_10_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2015" , paper: "2" , qns_num: "11", qns_image: "2015_2_11", ans_image: "2015_2_11_A", topic: "Linear law", sub_topic: "Plot graph"},

{year: "2016" , paper: "1" , qns_num: "1", qns_image: "2016_1_1", ans_image: "2016_1_1_A", topic: "Discriminant", sub_topic: "Discriminant"},
{year: "2016" , paper: "1" , qns_num: "2", qns_image: "2016_1_2", ans_image: "2016_1_2_A", topic: "Surds", sub_topic: "Surds"},
{year: "2016" , paper: "1" , qns_num: "3", qns_image: "2016_1_3", ans_image: "2016_1_3_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2016" , paper: "1" , qns_num: "4", qns_image: "2016_1_4", ans_image: "2016_1_4_A", topic: "Curve sketching", sub_topic: "Intersection point"},
{year: "2016" , paper: "1" , qns_num: "5", qns_image: "2016_1_5", ans_image: "2016_1_5_A", topic: "Partial fractions", sub_topic: "Partial fractions"},
{year: "2016" , paper: "1" , qns_num: "6", qns_image: "2016_1_6", ans_image: "2016_1_6_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2016" , paper: "1" , qns_num: "7", qns_image: "2016_1_7", ans_image: "2016_1_7_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2016" , paper: "1" , qns_num: "8", qns_image: "2016_1_8", ans_image: "2016_1_8_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2016" , paper: "1" , qns_num: "9", qns_image: "2016_1_9", ans_image: "2016_1_9_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2016" , paper: "1" , qns_num: "10", qns_image: "2016_1_10", ans_image: "2016_1_10_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2016" , paper: "1" , qns_num: "11", qns_image: "2016_1_11", ans_image: "2016_1_11_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2016" , paper: "1" , qns_num: "12", qns_image: "2016_1_12", ans_image: "2016_1_12_A", topic: "Differentiation", sub_topic: "Equation of normal"},

{year: "2016" , paper: "2" , qns_num: "1", qns_image: "2016_2_1", ans_image: "2016_2_1_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2016" , paper: "2" , qns_num: "2", qns_image: "2016_2_2", ans_image: "2016_2_2_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2016" , paper: "2" , qns_num: "3", qns_image: "2016_2_3", ans_image: "2016_2_3_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2016" , paper: "2" , qns_num: "4", qns_image: "2016_2_4", ans_image: "2016_2_4_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2016" , paper: "2" , qns_num: "5", qns_image: "2016_2_5", ans_image: "2016_2_5_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2016" , paper: "2" , qns_num: "6", qns_image: "2016_2_6", ans_image: "2016_2_6_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2016" , paper: "2" , qns_num: "7", qns_image: "2016_2_7", ans_image: "2016_2_7_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2016" , paper: "2" , qns_num: "8", qns_image: "2016_2_8", ans_image: "2016_2_8_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2016" , paper: "2" , qns_num: "9", qns_image: "2016_2_9", ans_image: "2016_2_9_A", topic: "Differentiation", sub_topic: "Integration"},
{year: "2016" , paper: "2" , qns_num: "10", qns_image: "2016_2_10", ans_image: "2016_2_10_A", topic: "Kinematics", sub_topic: "Differentiation Integration"},
{year: "2016" , paper: "2" , qns_num: "11", qns_image: "2016_2_11", ans_image: "2016_2_11_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

{year: "2017" , paper: "1" , qns_num: "1", qns_image: "2017_1_1", ans_image: "2017_1_1_A", topic: "Integration", sub_topic: "Integration twice"},
{year: "2017" , paper: "1" , qns_num: "2", qns_image: "2017_1_2", ans_image: "2017_1_2_A", topic: "Curve sketching", sub_topic: "Coordinate geometry"},
{year: "2017" , paper: "1" , qns_num: "3", qns_image: "2017_1_3", ans_image: "2017_1_3_A", topic: "Linear law", sub_topic: "Graph provided"},
{year: "2017" , paper: "1" , qns_num: "4", qns_image: "2017_1_4", ans_image: "2017_1_4_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2017" , paper: "1" , qns_num: "5", qns_image: "2017_1_5", ans_image: "2017_1_5_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2017" , paper: "1" , qns_num: "6", qns_image: "2017_1_6", ans_image: "2017_1_6_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2017" , paper: "1" , qns_num: "7", qns_image: "2017_1_7", ans_image: "2017_1_7_A", topic: "Surds", sub_topic: "Surds"},
{year: "2017" , paper: "1" , qns_num: "8", qns_image: "2017_1_8", ans_image: "2017_1_8_A", topic: "Partial fractions", sub_topic: "Partial fractions"},
{year: "2017" , paper: "1" , qns_num: "9", qns_image: "2017_1_9", ans_image: "2017_1_9_A", topic: "Kinematics", sub_topic: "Integration"},
{year: "2017" , paper: "1" , qns_num: "10", qns_image: "2017_1_10", ans_image: "2017_1_10_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2017" , paper: "1" , qns_num: "11", qns_image: "2017_1_11", ans_image: "2017_1_11_A", topic: "Integration", sub_topic: "Area under graph"},
{year: "2017" , paper: "1" , qns_num: "12", qns_image: "2017_1_12", ans_image: "2017_1_12_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

{year: "2017" , paper: "2" , qns_num: "1", qns_image: "2017_2_1", ans_image: "2017_2_1_A", topic: "Differentiation", sub_topic: "Differentiation of e"},
{year: "2017" , paper: "2" , qns_num: "2", qns_image: "2017_2_2", ans_image: "2017_2_2_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2017" , paper: "2" , qns_num: "3", qns_image: "2017_2_3", ans_image: "2017_2_3_A", topic: "Binomial expansion", sub_topic: "General term"},
{year: "2017" , paper: "2" , qns_num: "4", qns_image: "2017_2_4", ans_image: "2017_2_4_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2017" , paper: "2" , qns_num: "5", qns_image: "2017_2_5", ans_image: "2017_2_5_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2017" , paper: "2" , qns_num: "6", qns_image: "2017_2_6", ans_image: "2017_2_6_A", topic: "Discriminant", sub_topic: "Discriminant"},
{year: "2017" , paper: "2" , qns_num: "7", qns_image: "2017_2_7", ans_image: "2017_2_7_A", topic: "Logarithms", sub_topic: "Word problems"},
{year: "2017" , paper: "2" , qns_num: "8", qns_image: "2017_2_8", ans_image: "2017_2_8_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2017" , paper: "2" , qns_num: "9", qns_image: "2017_2_9", ans_image: "2017_2_9_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2017" , paper: "2" , qns_num: "10", qns_image: "2017_2_10", ans_image: "2017_2_10_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2017" , paper: "2" , qns_num: "11", qns_image: "2017_2_11", ans_image: "2017_2_11_A", topic: "Trigo", sub_topic: "R formula"},

{year: "2017Ben" , paper: "1" , qns_num: "1", qns_image: "2017Ben_1_1", ans_image: "2017Ben_1_1_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2017Ben" , paper: "1" , qns_num: "2", qns_image: "2017Ben_1_2", ans_image: "2017Ben_1_2_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2017Ben" , paper: "1" , qns_num: "3", qns_image: "2017Ben_1_3", ans_image: "2017Ben_1_3_A", topic: "Logarithms", sub_topic: "Log graph"},
{year: "2017Ben" , paper: "1" , qns_num: "4", qns_image: "2017Ben_1_4", ans_image: "2017Ben_1_4_A", topic: "Differentiation", sub_topic: "Differentiation of e"},
{year: "2017Ben" , paper: "1" , qns_num: "5", qns_image: "2017Ben_1_5", ans_image: "2017Ben_1_5_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2017Ben" , paper: "1" , qns_num: "6", qns_image: "2017Ben_1_6", ans_image: "2017Ben_1_6_A", topic: "Quadratic Inequality", sub_topic: "Discriminant"},
{year: "2017Ben" , paper: "1" , qns_num: "7", qns_image: "2017Ben_1_7", ans_image: "2017Ben_1_7_A", topic: "Partial fractions", sub_topic: "Integration"},
{year: "2017Ben" , paper: "1" , qns_num: "8", qns_image: "2017Ben_1_8", ans_image: "2017Ben_1_8_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2017Ben" , paper: "1" , qns_num: "9", qns_image: "2017Ben_1_9", ans_image: "2017Ben_1_9_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2017Ben" , paper: "1" , qns_num: "10", qns_image: "2017Ben_1_10", ans_image: "2017Ben_1_10_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2017Ben" , paper: "1" , qns_num: "11", qns_image: "2017Ben_1_11", ans_image: "2017Ben_1_11_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2017Ben" , paper: "1" , qns_num: "12", qns_image: "2017Ben_1_12", ans_image: "2017Ben_1_12_A", topic: "Trigo", sub_topic: "Trigo graph"},

{year: "2017Ben" , paper: "2" , qns_num: "1", qns_image: "2017Ben_2_1", ans_image: "2017Ben_2_1_A", topic: "Differentiation", sub_topic: "Equation of normal"},
{year: "2017Ben" , paper: "2" , qns_num: "2", qns_image: "2017Ben_2_2", ans_image: "2017Ben_2_2_A", topic: "Indices", sub_topic: "Substitution"},
{year: "2017Ben" , paper: "2" , qns_num: "3", qns_image: "2017Ben_2_3", ans_image: "2017Ben_2_3_A", topic: "Integration", sub_topic: "Integration"},
{year: "2017Ben" , paper: "2" , qns_num: "4", qns_image: "2017Ben_2_4", ans_image: "2017Ben_2_4_A", topic: "Binomial expansion", sub_topic: "General term"},
{year: "2017Ben" , paper: "2" , qns_num: "5", qns_image: "2017Ben_2_5", ans_image: "2017Ben_2_5_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2017Ben" , paper: "2" , qns_num: "6", qns_image: "2017Ben_2_6", ans_image: "2017Ben_2_6_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2017Ben" , paper: "2" , qns_num: "7", qns_image: "2017Ben_2_7", ans_image: "2017Ben_2_7_A", topic: "Integration", sub_topic: "Area under graph"},
{year: "2017Ben" , paper: "2" , qns_num: "8", qns_image: "2017Ben_2_8", ans_image: "2017Ben_2_8_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2017Ben" , paper: "2" , qns_num: "9", qns_image: "2017Ben_2_9", ans_image: "2017Ben_2_9_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2017Ben" , paper: "2" , qns_num: "10", qns_image: "2017Ben_2_10", ans_image: "2017Ben_2_10_A", topic: "Kinematics", sub_topic: "Differentiation"},
{year: "2017Ben" , paper: "2" , qns_num: "11", qns_image: "2017Ben_2_11", ans_image: "2017Ben_2_11_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2017Ben" , paper: "2" , qns_num: "12", qns_image: "2017Ben_2_12", ans_image: "2017Ben_2_12_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2017Ben" , paper: "2" , qns_num: "13", qns_image: "2017Ben_2_13", ans_image: "2017Ben_2_13_A", topic: "Trigo", sub_topic: "R formula"},

{year: "2017Csj" , paper: "1" , qns_num: "1", qns_image: "2017Csj_1_1", ans_image: "2017Csj_1_1_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2017Csj" , paper: "1" , qns_num: "2", qns_image: "2017Csj_1_2", ans_image: "2017Csj_1_2_A", topic: "Discriminant", sub_topic: "Discriminant"},
{year: "2017Csj" , paper: "1" , qns_num: "3", qns_image: "2017Csj_1_3", ans_image: "2017Csj_1_3_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2017Csj" , paper: "1" , qns_num: "4", qns_image: "2017Csj_1_4", ans_image: "2017Csj_1_4_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2017Csj" , paper: "1" , qns_num: "5", qns_image: "2017Csj_1_5", ans_image: "2017Csj_1_5_A", topic: "Differentiation", sub_topic: "Equation of tangent"},
{year: "2017Csj" , paper: "1" , qns_num: "6", qns_image: "2017Csj_1_6", ans_image: "2017Csj_1_6_A", topic: "Integration", sub_topic: "Equation of normal"},
{year: "2017Csj" , paper: "1" , qns_num: "7", qns_image: "2017Csj_1_7", ans_image: "2017Csj_1_7_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2017Csj" , paper: "1" , qns_num: "8", qns_image: "2017Csj_1_8", ans_image: "2017Csj_1_8_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2017Csj" , paper: "1" , qns_num: "9", qns_image: "2017Csj_1_9", ans_image: "2017Csj_1_9_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2017Csj" , paper: "1" , qns_num: "10", qns_image: "2017Csj_1_10", ans_image: "2017Csj_1_10_A", topic: "Linear law", sub_topic: "Graph provided"},
{year: "2017Csj" , paper: "1" , qns_num: "11", qns_image: "2017Csj_1_11", ans_image: "2017Csj_1_11_A", topic: "Trigo", sub_topic: "Trigo graph"},

{year: "2017Csj" , paper: "2" , qns_num: "1", qns_image: "2017Csj_2_1", ans_image: "2017Csj_2_1_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2017Csj" , paper: "2" , qns_num: "2", qns_image: "2017Csj_2_2", ans_image: "2017Csj_2_2_A", topic: "Discriminant", sub_topic: "Discriminant"},
{year: "2017Csj" , paper: "2" , qns_num: "3", qns_image: "2017Csj_2_3", ans_image: "2017Csj_2_3_A", topic: "Surds", sub_topic: "Surds"},
{year: "2017Csj" , paper: "2" , qns_num: "4", qns_image: "2017Csj_2_4", ans_image: "2017Csj_2_4_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2017Csj" , paper: "2" , qns_num: "5", qns_image: "2017Csj_2_5", ans_image: "2017Csj_2_5_A", topic: "Partial fractions", sub_topic: "Differentiation"},
{year: "2017Csj" , paper: "2" , qns_num: "6", qns_image: "2017Csj_2_6", ans_image: "2017Csj_2_6_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2017Csj" , paper: "2" , qns_num: "7", qns_image: "2017Csj_2_7", ans_image: "2017Csj_2_7_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2017Csj" , paper: "2" , qns_num: "8", qns_image: "2017Csj_2_8", ans_image: "2017Csj_2_8_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2017Csj" , paper: "2" , qns_num: "9", qns_image: "2017Csj_2_9", ans_image: "2017Csj_2_9_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2017Csj" , paper: "2" , qns_num: "10", qns_image: "2017Csj_2_10", ans_image: "2017Csj_2_10_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2017Csj" , paper: "2" , qns_num: "11", qns_image: "2017Csj_2_11", ans_image: "2017Csj_2_11_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2017Csj" , paper: "2" , qns_num: "12", qns_image: "2017Csj_2_12", ans_image: "2017Csj_2_12_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

{year: "2017Mhs" , paper: "1" , qns_num: "1", qns_image: "2017Mhs_1_1", ans_image: "2017Mhs_1_1_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2017Mhs" , paper: "1" , qns_num: "2", qns_image: "2017Mhs_1_2", ans_image: "2017Mhs_1_2_A", topic: "Integration", sub_topic: "Partial fractions"},
{year: "2017Mhs" , paper: "1" , qns_num: "3", qns_image: "2017Mhs_1_3", ans_image: "2017Mhs_1_3_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2017Mhs" , paper: "1" , qns_num: "4", qns_image: "2017Mhs_1_4", ans_image: "2017Mhs_1_4_A", topic: "Integration", sub_topic: "Differentiation"},
{year: "2017Mhs" , paper: "1" , qns_num: "5", qns_image: "2017Mhs_1_5", ans_image: "2017Mhs_1_5_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2017Mhs" , paper: "1" , qns_num: "6", qns_image: "2017Mhs_1_6", ans_image: "2017Mhs_1_6_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2017Mhs" , paper: "1" , qns_num: "7", qns_image: "2017Mhs_1_7", ans_image: "2017Mhs_1_7_A", topic: "Differentiation", sub_topic: "Equation of tangent"},
{year: "2017Mhs" , paper: "1" , qns_num: "8", qns_image: "2017Mhs_1_8", ans_image: "2017Mhs_1_8_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2017Mhs" , paper: "1" , qns_num: "9", qns_image: "2017Mhs_1_9", ans_image: "2017Mhs_1_9_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2017Mhs" , paper: "1" , qns_num: "10", qns_image: "2017Mhs_1_10", ans_image: "2017Mhs_1_10_A", topic: "Differentiation", sub_topic: "Differentiation of e"},
{year: "2017Mhs" , paper: "1" , qns_num: "11", qns_image: "2017Mhs_1_11", ans_image: "2017Mhs_1_11_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2017Mhs" , paper: "1" , qns_num: "12", qns_image: "2017Mhs_1_12", ans_image: "2017Mhs_1_12_A", topic: "Logarithms", sub_topic: "Log graph"},
{year: "2017Mhs" , paper: "1" , qns_num: "13", qns_image: "2017Mhs_1_13", ans_image: "2017Mhs_1_13_A", topic: "Indices", sub_topic: "Surds"},

{year: "2017Nhs" , paper: "1" , qns_num: "1", qns_image: "2017Nhs_1_1", ans_image: "2017Nhs_1_1_A", topic: "Surds", sub_topic: "Surds"},
{year: "2017Nhs" , paper: "1" , qns_num: "2", qns_image: "2017Nhs_1_2", ans_image: "2017Nhs_1_2_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2017Nhs" , paper: "1" , qns_num: "3", qns_image: "2017Nhs_1_3", ans_image: "2017Nhs_1_3_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2017Nhs" , paper: "1" , qns_num: "4", qns_image: "2017Nhs_1_4", ans_image: "2017Nhs_1_4_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2017Nhs" , paper: "1" , qns_num: "5", qns_image: "2017Nhs_1_5", ans_image: "2017Nhs_1_5_A", topic: "Integration", sub_topic: "Integration"},
{year: "2017Nhs" , paper: "1" , qns_num: "6", qns_image: "2017Nhs_1_6", ans_image: "2017Nhs_1_6_A", topic: "Indices", sub_topic: "Substitution"},
{year: "2017Nhs" , paper: "1" , qns_num: "7", qns_image: "2017Nhs_1_7", ans_image: "2017Nhs_1_7_A", topic: "Binomial expansion", sub_topic: "General term"},
{year: "2017Nhs" , paper: "1" , qns_num: "8", qns_image: "2017Nhs_1_8", ans_image: "2017Nhs_1_8_A", topic: "Partial fractions", sub_topic: "Integration"},
{year: "2017Nhs" , paper: "1" , qns_num: "9", qns_image: "2017Nhs_1_9", ans_image: "2017Nhs_1_9_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2017Nhs" , paper: "1" , qns_num: "10", qns_image: "2017Nhs_1_10", ans_image: "2017Nhs_1_10_A", topic: "Trigo", sub_topic: "Trigo equations"},
{year: "2017Nhs" , paper: "1" , qns_num: "11", qns_image: "2017Nhs_1_11", ans_image: "2017Nhs_1_11_A", topic: "Modulus function", sub_topic: "Modulus function"},

{year: "2017Nhs" , paper: "2" , qns_num: "1", qns_image: "2017Nhs_2_1", ans_image: "2017Nhs_2_1_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2017Nhs" , paper: "2" , qns_num: "2", qns_image: "2017Nhs_2_2", ans_image: "2017Nhs_2_2_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2017Nhs" , paper: "2" , qns_num: "3", qns_image: "2017Nhs_2_3", ans_image: "2017Nhs_2_3_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2017Nhs" , paper: "2" , qns_num: "4", qns_image: "2017Nhs_2_4", ans_image: "2017Nhs_2_4_A", topic: "Logarithms", sub_topic: "Word problems"},
{year: "2017Nhs" , paper: "2" , qns_num: "5", qns_image: "2017Nhs_2_5", ans_image: "2017Nhs_2_5_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2017Nhs" , paper: "2" , qns_num: "6", qns_image: "2017Nhs_2_6", ans_image: "2017Nhs_2_6_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2017Nhs" , paper: "2" , qns_num: "7", qns_image: "2017Nhs_2_7", ans_image: "2017Nhs_2_7_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2017Nhs" , paper: "2" , qns_num: "8", qns_image: "2017Nhs_2_8", ans_image: "2017Nhs_2_8_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2017Nhs" , paper: "2" , qns_num: "9", qns_image: "2017Nhs_2_9", ans_image: "2017Nhs_2_9_A", topic: "Kinematics", sub_topic: "Differentiation Integration"},
{year: "2017Nhs" , paper: "2" , qns_num: "10", qns_image: "2017Nhs_2_10", ans_image: "2017Nhs_2_10_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2017Nhs" , paper: "2" , qns_num: "11", qns_image: "2017Nhs_2_11", ans_image: "2017Nhs_2_11_A", topic: "Integration", sub_topic: "Area under graph"},

{year: "2018" , paper: "1" , qns_num: "1", qns_image: "2018_1_1", ans_image: "2018_1_1_A", topic: "Indices", sub_topic: "Indices"},
{year: "2018" , paper: "1" , qns_num: "2", qns_image: "2018_1_2", ans_image: "2018_1_2_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2018" , paper: "1" , qns_num: "3", qns_image: "2018_1_3", ans_image: "2018_1_3_A", topic: "Partial fractions", sub_topic: "Partial fractions"},
{year: "2018" , paper: "1" , qns_num: "4", qns_image: "2018_1_4", ans_image: "2018_1_4_A", topic: "Surds", sub_topic: "Surds"},
{year: "2018" , paper: "1" , qns_num: "5", qns_image: "2018_1_5", ans_image: "2018_1_5_A", topic: "Curve sketching", sub_topic: "Curve sketching"},
{year: "2018" , paper: "1" , qns_num: "6", qns_image: "2018_1_6", ans_image: "2018_1_6_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2018" , paper: "1" , qns_num: "7", qns_image: "2018_1_7", ans_image: "2018_1_7_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2018" , paper: "1" , qns_num: "8", qns_image: "2018_1_8", ans_image: "2018_1_8_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2018" , paper: "1" , qns_num: "9", qns_image: "2018_1_9", ans_image: "2018_1_9_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2018" , paper: "1" , qns_num: "10", qns_image: "2018_1_10", ans_image: "2018_1_10_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2018" , paper: "1" , qns_num: "11", qns_image: "2018_1_11", ans_image: "2018_1_11_A", topic: "Differentiation", sub_topic: "Increasing decreasing function"},
{year: "2018" , paper: "1" , qns_num: "12", qns_image: "2018_1_12", ans_image: "2018_1_12_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2018" , paper: "2" , qns_num: "1", qns_image: "2018_2_1", ans_image: "2018_2_1_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2018" , paper: "2" , qns_num: "2", qns_image: "2018_2_2", ans_image: "2018_2_2_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2018" , paper: "2" , qns_num: "3", qns_image: "2018_2_3", ans_image: "2018_2_3_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2018" , paper: "2" , qns_num: "4", qns_image: "2018_2_4", ans_image: "2018_2_4_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2018" , paper: "2" , qns_num: "5", qns_image: "2018_2_5", ans_image: "2018_2_5_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2018" , paper: "2" , qns_num: "6", qns_image: "2018_2_6", ans_image: "2018_2_6_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2018" , paper: "2" , qns_num: "7", qns_image: "2018_2_7", ans_image: "2018_2_7_A", topic: "Kinematics", sub_topic: "Differentiation"},
{year: "2018" , paper: "2" , qns_num: "8", qns_image: "2018_2_8", ans_image: "2018_2_8_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2018" , paper: "2" , qns_num: "9", qns_image: "2018_2_9", ans_image: "2018_2_9_A", topic: "Discriminant", sub_topic: "Discriminant"},
{year: "2018" , paper: "2" , qns_num: "10", qns_image: "2018_2_10", ans_image: "2018_2_10_A", topic: "Differentiation", sub_topic: "Integration"},
{year: "2018" , paper: "2" , qns_num: "11", qns_image: "2018_2_11", ans_image: "2018_2_11_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
 
{year: "2018Kra" , paper: "1" , qns_num: "1", qns_image: "2018Kra_1_1", ans_image: "2018Kra_1_1_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2018Kra" , paper: "1" , qns_num: "2", qns_image: "2018Kra_1_2", ans_image: "2018Kra_1_2_A", topic: "Discriminant", sub_topic: "Always positive"},
{year: "2018Kra" , paper: "1" , qns_num: "3", qns_image: "2018Kra_1_3", ans_image: "2018Kra_1_3_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2018Kra" , paper: "1" , qns_num: "4", qns_image: "2018Kra_1_4", ans_image: "2018Kra_1_4_A", topic: "Integration", sub_topic: "Integration"},
{year: "2018Kra" , paper: "1" , qns_num: "5", qns_image: "2018Kra_1_5", ans_image: "2018Kra_1_5_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2018Kra" , paper: "1" , qns_num: "6", qns_image: "2018Kra_1_6", ans_image: "2018Kra_1_6_A", topic: "Logarithms", sub_topic: "Word problems"},
{year: "2018Kra" , paper: "1" , qns_num: "7", qns_image: "2018Kra_1_7", ans_image: "2018Kra_1_7_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2018Kra" , paper: "1" , qns_num: "8", qns_image: "2018Kra_1_8", ans_image: "2018Kra_1_8_A", topic: "Trigo", sub_topic: "Drawing triangles"},
{year: "2018Kra" , paper: "1" , qns_num: "9", qns_image: "2018Kra_1_9", ans_image: "2018Kra_1_9_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2018Kra" , paper: "1" , qns_num: "10", qns_image: "2018Kra_1_10", ans_image: "2018Kra_1_10_A", topic: "Surds", sub_topic: "Surds"},
{year: "2018Kra" , paper: "1" , qns_num: "11", qns_image: "2018Kra_1_11", ans_image: "2018Kra_1_11_A", topic: "Partial fractions", sub_topic: "Integration"},
{year: "2018Kra" , paper: "1" , qns_num: "12", qns_image: "2018Kra_1_12", ans_image: "2018Kra_1_12_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},

{year: "2018Kra" , paper: "2" , qns_num: "1", qns_image: "2018Kra_2_1", ans_image: "2018Kra_2_1_A", topic: "Integration", sub_topic: "Area under graph"},
{year: "2018Kra" , paper: "2" , qns_num: "2", qns_image: "2018Kra_2_2", ans_image: "2018Kra_2_2_A", topic: "Linear law", sub_topic: "Graph provided"},
{year: "2018Kra" , paper: "2" , qns_num: "3", qns_image: "2018Kra_2_3", ans_image: "2018Kra_2_3_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2018Kra" , paper: "2" , qns_num: "4", qns_image: "2018Kra_2_4", ans_image: "2018Kra_2_4_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2018Kra" , paper: "2" , qns_num: "5", qns_image: "2018Kra_2_5", ans_image: "2018Kra_2_5_A", topic: "Differentiation", sub_topic: "Increasing function"},
{year: "2018Kra" , paper: "2" , qns_num: "6", qns_image: "2018Kra_2_6", ans_image: "2018Kra_2_6_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2018Kra" , paper: "2" , qns_num: "7", qns_image: "2018Kra_2_7", ans_image: "2018Kra_2_7_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2018Kra" , paper: "2" , qns_num: "8", qns_image: "2018Kra_2_8", ans_image: "2018Kra_2_8_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2018Kra" , paper: "2" , qns_num: "9", qns_image: "2018Kra_2_9", ans_image: "2018Kra_2_9_A", topic: "Kinematics", sub_topic: "Differentiation Integration"},
{year: "2018Kra" , paper: "2" , qns_num: "10", qns_image: "2018Kra_2_10", ans_image: "2018Kra_2_10_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2018Kra" , paper: "2" , qns_num: "11", qns_image: "2018Kra_2_11", ans_image: "2018Kra_2_11_A", topic: "Binomial expansion", sub_topic: "General term"},
{year: "2018Kra" , paper: "2" , qns_num: "12", qns_image: "2018Kra_2_12", ans_image: "2018Kra_2_12_A", topic: "Trigo", sub_topic: "R formula"},

{year: "2019" , paper: "1" , qns_num: "1", qns_image: "2019_1_1", ans_image: "2019_1_1_A", topic: "Trigo", sub_topic: "Drawing triangles"},
    {year: "2019" , paper: "1" , qns_num: "2", qns_image: "2019_1_2", ans_image: "2019_1_2_A", topic: "Discriminant", sub_topic: "No real roots"},
    {year: "2019" , paper: "1" , qns_num: "3", qns_image: "2019_1_3", ans_image: "2019_1_3_A", topic: "Differentiation", sub_topic: "Differentiation of e"},
    {year: "2019" , paper: "1" , qns_num: "4", qns_image: "2019_1_4", ans_image: "2019_1_4_A", topic: "Differentiation", sub_topic: "Rate of change"},
    {year: "2019" , paper: "1" , qns_num: "5", qns_image: "2019_1_5", ans_image: "2019_1_5_A", topic: "Logarithms", sub_topic: "Word problems"},
    {year: "2019" , paper: "1" , qns_num: "6", qns_image: "2019_1_6", ans_image: "2019_1_6_A", topic: "Geometric proof", sub_topic: "Circle properties"},
    {year: "2019" , paper: "1" , qns_num: "7", qns_image: "2019_1_7", ans_image: "2019_1_7_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
    {year: "2019" , paper: "1" , qns_num: "8", qns_image: "2019_1_8", ans_image: "2019_1_8_A", topic: "Differentiation", sub_topic: "Stationary points"},
    {year: "2019" , paper: "1" , qns_num: "9", qns_image: "2019_1_9", ans_image: "2019_1_9_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
    {year: "2019" , paper: "1" , qns_num: "10", qns_image: "2019_1_10", ans_image: "2019_1_10_A", topic: "Indices", sub_topic: "Surds"},
    {year: "2019" , paper: "1" , qns_num: "11", qns_image: "2019_1_11", ans_image: "2019_1_11_A", topic: "Kinematics", sub_topic: "Differentiation"},
    {year: "2019" , paper: "1" , qns_num: "12", qns_image: "2019_1_12", ans_image: "2019_1_12_A", topic: "Trigo", sub_topic: "Trigo graph"},
    {year: "2019" , paper: "2" , qns_num: "1", qns_image: "2019_2_1", ans_image: "2019_2_1_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
    {year: "2019" , paper: "2" , qns_num: "2", qns_image: "2019_2_2", ans_image: "2019_2_2_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
    {year: "2019" , paper: "2" , qns_num: "3", qns_image: "2019_2_3", ans_image: "2019_2_3_A", topic: "Partial fractions", sub_topic: "Partial fractions"},
    {year: "2019" , paper: "2" , qns_num: "4", qns_image: "2019_2_4", ans_image: "2019_2_4_A", topic: "Logarithms", sub_topic: "Log equations"},
    {year: "2019" , paper: "2" , qns_num: "5", qns_image: "2019_2_5", ans_image: "2019_2_5_A", topic: "Integration", sub_topic: "Integration trigo"},
    {year: "2019" , paper: "2" , qns_num: "6", qns_image: "2019_2_6", ans_image: "2019_2_6_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
    {year: "2019" , paper: "2" , qns_num: "7", qns_image: "2019_2_7", ans_image: "2019_2_7_A", topic: "Modulus function", sub_topic: "Modulus function"},
    {year: "2019" , paper: "2" , qns_num: "8", qns_image: "2019_2_8", ans_image: "2019_2_8_A", topic: "Linear law", sub_topic: "Plot graph"},
    {year: "2019" , paper: "2" , qns_num: "9", qns_image: "2019_2_9", ans_image: "2019_2_9_A", topic: "Integration", sub_topic: "Area under graph"},
    {year: "2019" , paper: "2" , qns_num: "10", qns_image: "2019_2_10", ans_image: "2019_2_10_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
    {year: "2019" , paper: "2" , qns_num: "11", qns_image: "2019_2_11", ans_image: "2019_2_11_A", topic: "Trigo", sub_topic: "R formula"},

    {year: "2019Bow" , paper: "1" , qns_num: "1", qns_image: "2019Bow_1_1", ans_image: "2019Bow_1_1_A", topic: "Indices", sub_topic: "Indices"},
{year: "2019Bow" , paper: "1" , qns_num: "2", qns_image: "2019Bow_1_2", ans_image: "2019Bow_1_2_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2019Bow" , paper: "1" , qns_num: "3", qns_image: "2019Bow_1_3", ans_image: "2019Bow_1_3_A", topic: "Partial fractions", sub_topic: "Integration"},
{year: "2019Bow" , paper: "1" , qns_num: "4", qns_image: "2019Bow_1_4", ans_image: "2019Bow_1_4_A", topic: "Surds", sub_topic: "Surds"},
{year: "2019Bow" , paper: "1" , qns_num: "5", qns_image: "2019Bow_1_5", ans_image: "2019Bow_1_5_A", topic: "Curve sketching", sub_topic: "Curve sketching"},
{year: "2019Bow" , paper: "1" , qns_num: "6", qns_image: "2019Bow_1_6", ans_image: "2019Bow_1_6_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2019Bow" , paper: "1" , qns_num: "7", qns_image: "2019Bow_1_7", ans_image: "2019Bow_1_7_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2019Bow" , paper: "1" , qns_num: "8", qns_image: "2019Bow_1_8", ans_image: "2019Bow_1_8_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2019Bow" , paper: "1" , qns_num: "9", qns_image: "2019Bow_1_9", ans_image: "2019Bow_1_9_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2019Bow" , paper: "1" , qns_num: "10", qns_image: "2019Bow_1_10", ans_image: "2019Bow_1_10_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2019Bow" , paper: "1" , qns_num: "11", qns_image: "2019Bow_1_11", ans_image: "2019Bow_1_11_A", topic: "Differentiation", sub_topic: "Differentiation of trigo"},
{year: "2019Bow" , paper: "1" , qns_num: "12", qns_image: "2019Bow_1_12", ans_image: "2019Bow_1_12_A", topic: "Modulus function", sub_topic: "Modulus function"},

{year: "2019Bow" , paper: "2" , qns_num: "1", qns_image: "2019Bow_2_1", ans_image: "2019Bow_2_1_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2019Bow" , paper: "2" , qns_num: "2", qns_image: "2019Bow_2_2", ans_image: "2019Bow_2_2_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2019Bow" , paper: "2" , qns_num: "3", qns_image: "2019Bow_2_3", ans_image: "2019Bow_2_3_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2019Bow" , paper: "2" , qns_num: "4", qns_image: "2019Bow_2_4", ans_image: "2019Bow_2_4_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2019Bow" , paper: "2" , qns_num: "5", qns_image: "2019Bow_2_5", ans_image: "2019Bow_2_5_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2019Bow" , paper: "2" , qns_num: "6", qns_image: "2019Bow_2_6", ans_image: "2019Bow_2_6_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2019Bow" , paper: "2" , qns_num: "7", qns_image: "2019Bow_2_7", ans_image: "2019Bow_2_7_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2019Bow" , paper: "2" , qns_num: "8", qns_image: "2019Bow_2_8", ans_image: "2019Bow_2_8_A", topic: "Kinematics", sub_topic: "Differentiation Integration"},
{year: "2019Bow" , paper: "2" , qns_num: "9", qns_image: "2019Bow_2_9", ans_image: "2019Bow_2_9_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2019Bow" , paper: "2" , qns_num: "10", qns_image: "2019Bow_2_10", ans_image: "2019Bow_2_10_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2019Bow" , paper: "2" , qns_num: "11", qns_image: "2019Bow_2_11", ans_image: "2019Bow_2_11_A", topic: "Quadratic equation", sub_topic: "Quadratic equation"},
{year: "2019Bow" , paper: "2" , qns_num: "12", qns_image: "2019Bow_2_12", ans_image: "2019Bow_2_12_A", topic: "Coordinate geometry", sub_topic: "Non circle"},

    {year: "2020" , paper: "1" , qns_num: "1", qns_image: "2020_1_1", ans_image: "2020_1_1_A", topic: "Alpha beta", sub_topic: "Alpha beta"},  
{year: "2020" , paper: "1" , qns_num: "2", qns_image: "2020_1_2", ans_image: "2020_1_2_A", topic: "Indices", sub_topic: "Word problem"},
{year: "2020" , paper: "1" , qns_num: "3", qns_image: "2020_1_3", ans_image: "2020_1_3_A", topic: "Partial fractions", sub_topic: "Partial fractions"}, 
{year: "2020" , paper: "1" , qns_num: "4", qns_image: "2020_1_4", ans_image: "2020_1_4_A", topic: "Differentiation", sub_topic: "Increasing function"}, 
{year: "2020" , paper: "1" , qns_num: "5", qns_image: "2020_1_5", ans_image: "2020_1_5_A", topic: "Modulus function", sub_topic: "Modulus function"}, 
{year: "2020" , paper: "1" , qns_num: "6", qns_image: "2020_1_6", ans_image: "2020_1_6_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2020" , paper: "1" , qns_num: "7", qns_image: "2020_1_7", ans_image: "2020_1_7_A", topic: "Trigo", sub_topic: "Trigo equations"}, 
{year: "2020" , paper: "1" , qns_num: "8", qns_image: "2020_1_8", ans_image: "2020_1_8_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"}, 
{year: "2020" , paper: "1" , qns_num: "9", qns_image: "2020_1_9", ans_image: "2020_1_9_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"}, 
{year: "2020" , paper: "1" , qns_num: "10", qns_image: "2020_1_10", ans_image: "2020_1_10_A", topic: "Integration", sub_topic: "Area under graph"}, 
{year: "2020" , paper: "1" , qns_num: "11", qns_image: "2020_1_11", ans_image: "2020_1_11_A", topic: "Kinematics", sub_topic: "Differentiation Integration"}, 
{year: "2020" , paper: "1" , qns_num: "12", qns_image: "2020_1_12", ans_image: "2020_1_12_A", topic: "Trigo", sub_topic: "Trigo proof"},   
{year: "2020" , paper: "2" , qns_num: "1", qns_image: "2020_2_1", ans_image: "2020_2_1_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2020" , paper: "2" , qns_num: "2", qns_image: "2020_2_2", ans_image: "2020_2_2_A", topic: "Discriminant", sub_topic: "Always negative"},
{year: "2020" , paper: "2" , qns_num: "3", qns_image: "2020_2_3", ans_image: "2020_2_3_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2020" , paper: "2" , qns_num: "4", qns_image: "2020_2_4", ans_image: "2020_2_4_A", topic: "Geometric proof", sub_topic: "Circle properties"},
{year: "2020" , paper: "2" , qns_num: "5", qns_image: "2020_2_5", ans_image: "2020_2_5_A", topic: "Quadratic Inequality", sub_topic: "Quadratic Inequality"},
{year: "2020" , paper: "2" , qns_num: "6", qns_image: "2020_2_6", ans_image: "2020_2_6_A", topic: "Linear law", sub_topic: "Plot graph"},
{year: "2020" , paper: "2" , qns_num: "7", qns_image: "2020_2_7", ans_image: "2020_2_7_A", topic: "Differentiation", sub_topic: "Integration"},
{year: "2020" , paper: "2" , qns_num: "8", qns_image: "2020_2_8", ans_image: "2020_2_8_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2020" , paper: "2" , qns_num: "9", qns_image: "2020_2_9", ans_image: "2020_2_9_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2020" , paper: "2" , qns_num: "10", qns_image: "2020_2_10", ans_image: "2020_2_10_A", topic: "Integration", sub_topic: "Equation of tangent"},
{year: "2020" , paper: "2" , qns_num: "11", qns_image: "2020_2_11", ans_image: "2020_2_11_A", topic: "Differentiation", sub_topic: "Rate of change"},

{year: "2020Bhs" , paper: "1" , qns_num: "1", qns_image: "2020Bhs_1_1", ans_image: "2020Bhs_1_1_A", topic: "Trigo", sub_topic: "Drawing triangles"},
{year: "2020Bhs" , paper: "1" , qns_num: "2", qns_image: "2020Bhs_1_2", ans_image: "2020Bhs_1_2_A", topic: "Discriminant", sub_topic: "Discriminant"},
{year: "2020Bhs" , paper: "1" , qns_num: "3", qns_image: "2020Bhs_1_3", ans_image: "2020Bhs_1_3_A", topic: "Differentiation", sub_topic: "Integration"},
{year: "2020Bhs" , paper: "1" , qns_num: "4", qns_image: "2020Bhs_1_4", ans_image: "2020Bhs_1_4_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2020Bhs" , paper: "1" , qns_num: "5", qns_image: "2020Bhs_1_5", ans_image: "2020Bhs_1_5_A", topic: "Binomial expansion", sub_topic: "General term"},
{year: "2020Bhs" , paper: "1" , qns_num: "6", qns_image: "2020Bhs_1_6", ans_image: "2020Bhs_1_6_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2020Bhs" , paper: "1" , qns_num: "7", qns_image: "2020Bhs_1_7", ans_image: "2020Bhs_1_7_A", topic: "Surds", sub_topic: "Indices"},
{year: "2020Bhs" , paper: "1" , qns_num: "8", qns_image: "2020Bhs_1_8", ans_image: "2020Bhs_1_8_A", topic: "Kinematics", sub_topic: "Differentiation Integration"},
{year: "2020Bhs" , paper: "1" , qns_num: "9", qns_image: "2020Bhs_1_9", ans_image: "2020Bhs_1_9_A", topic: "Trigo", sub_topic: "Trigo graph"},
{year: "2020Bhs" , paper: "1" , qns_num: "10", qns_image: "2020Bhs_1_10", ans_image: "2020Bhs_1_10_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2020Bhs" , paper: "1" , qns_num: "11", qns_image: "2020Bhs_1_11", ans_image: "2020Bhs_1_11_A", topic: "Logarithms", sub_topic: "Word problems"},

{year: "2020Bhs" , paper: "2" , qns_num: "1", qns_image: "2020Bhs_2_1", ans_image: "2020Bhs_2_1_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2020Bhs" , paper: "2" , qns_num: "2", qns_image: "2020Bhs_2_2", ans_image: "2020Bhs_2_2_A", topic: "Partial fractions", sub_topic: "Discriminant"},
{year: "2020Bhs" , paper: "2" , qns_num: "3", qns_image: "2020Bhs_2_3", ans_image: "2020Bhs_2_3_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2020Bhs" , paper: "2" , qns_num: "4", qns_image: "2020Bhs_2_4", ans_image: "2020Bhs_2_4_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2020Bhs" , paper: "2" , qns_num: "5", qns_image: "2020Bhs_2_5", ans_image: "2020Bhs_2_5_A", topic: "Differentiation", sub_topic: "Differentiation of ln"},
{year: "2020Bhs" , paper: "2" , qns_num: "6", qns_image: "2020Bhs_2_6", ans_image: "2020Bhs_2_6_A", topic: "Trigo", sub_topic: "Trigo proof equation"},
{year: "2020Bhs" , paper: "2" , qns_num: "7", qns_image: "2020Bhs_2_7", ans_image: "2020Bhs_2_7_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2020Bhs" , paper: "2" , qns_num: "8", qns_image: "2020Bhs_2_8", ans_image: "2020Bhs_2_8_A", topic: "Integration", sub_topic: "Area under graph"},
{year: "2020Bhs" , paper: "2" , qns_num: "9", qns_image: "2020Bhs_2_9", ans_image: "2020Bhs_2_9_A", topic: "Modulus function", sub_topic: "Modulus function"},
{year: "2020Bhs" , paper: "2" , qns_num: "10", qns_image: "2020Bhs_2_10", ans_image: "2020Bhs_2_10_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2020Bhs" , paper: "2" , qns_num: "11", qns_image: "2020Bhs_2_11", ans_image: "2020Bhs_2_11_A", topic: "Linear law", sub_topic: "Plot graph"},


{year: "2020And" , paper: "1" , qns_num: "1", qns_image: "2020And_1_1", ans_image: "2020And_1_1_A", topic: "Trigo", sub_topic: "Drawing triangles"},
{year: "2020And" , paper: "1" , qns_num: "2", qns_image: "2020And_1_2", ans_image: "2020And_1_2_A", topic: "Discriminant", sub_topic: "No real roots"},
{year: "2020And" , paper: "1" , qns_num: "3", qns_image: "2020And_1_3", ans_image: "2020And_1_3_A", topic: "Differentiation", sub_topic: "Differentiation of e"},
{year: "2020And" , paper: "1" , qns_num: "4", qns_image: "2020And_1_4", ans_image: "2020And_1_4_A", topic: "Differentiation", sub_topic: "Rate of change"},
{year: "2020And" , paper: "1" , qns_num: "5", qns_image: "2020And_1_5", ans_image: "2020And_1_5_A", topic: "Logarithms", sub_topic: "Word problems"},
{year: "2020And" , paper: "1" , qns_num: "6", qns_image: "2020And_1_6", ans_image: "2020And_1_6_A", topic: "Remainder factor theorem", sub_topic: "Remainder factor theorem"},
{year: "2020And" , paper: "1" , qns_num: "7", qns_image: "2020And_1_7", ans_image: "2020And_1_7_A", topic: "Binomial expansion", sub_topic: "Binomial expansion"},
{year: "2020And" , paper: "1" , qns_num: "8", qns_image: "2020And_1_8", ans_image: "2020And_1_8_A", topic: "Differentiation", sub_topic: "Stationary points"},
{year: "2020And" , paper: "1" , qns_num: "9", qns_image: "2020And_1_9", ans_image: "2020And_1_9_A", topic: "Coordinate geometry", sub_topic: "Non circle"},
{year: "2020And" , paper: "1" , qns_num: "10", qns_image: "2020And_1_10", ans_image: "2020And_1_10_A", topic: "Indices", sub_topic: "Surds"},
{year: "2020And" , paper: "1" , qns_num: "11", qns_image: "2020And_1_11", ans_image: "2020And_1_11_A", topic: "Kinematics", sub_topic: "Integration"},
{year: "2020And" , paper: "1" , qns_num: "12", qns_image: "2020And_1_12", ans_image: "2020And_1_12_A", topic: "Trigo", sub_topic: "Trigo graph"},
  
{year: "2020And" , paper: "2" , qns_num: "1", qns_image: "2020And_2_1", ans_image: "2020And_2_1_A", topic: "Alpha beta", sub_topic: "Alpha beta"},
{year: "2020And" , paper: "2" , qns_num: "2", qns_image: "2020And_2_2", ans_image: "2020And_2_2_A", topic: "Trigo", sub_topic: "Trigo proof"},
{year: "2020And" , paper: "2" , qns_num: "3", qns_image: "2020And_2_3", ans_image: "2020And_2_3_A", topic: "Coordinate geometry", sub_topic: "Equation of circle"},
{year: "2020And" , paper: "2" , qns_num: "4", qns_image: "2020And_2_4", ans_image: "2020And_2_4_A", topic: "Logarithms", sub_topic: "Log equations"},
{year: "2020And" , paper: "2" , qns_num: "5", qns_image: "2020And_2_5", ans_image: "2020And_2_5_A", topic: "Differentiation", sub_topic: "Stationary value"},
{year: "2020And" , paper: "2" , qns_num: "6", qns_image: "2020And_2_6", ans_image: "2020And_2_6_A", topic: "Integration", sub_topic: "Integration opp of differentiation"},
{year: "2020And" , paper: "2" , qns_num: "7", qns_image: "2020And_2_7", ans_image: "2020And_2_7_A", topic: "Integration", sub_topic: "Area under graph"},
{year: "2020And" , paper: "2" , qns_num: "8", qns_image: "2020And_2_8", ans_image: "2020And_2_8_A", topic: "Modulus function", sub_topic: "Quadratic Inequality"},
{year: "2020And" , paper: "2" , qns_num: "9", qns_image: "2020And_2_9", ans_image: "2020And_2_9_A", topic: "Trigo", sub_topic: "R formula"},
{year: "2020And" , paper: "2" , qns_num: "10", qns_image: "2020And_2_10", ans_image: "2020And_2_10_A", topic: "Linear law", sub_topic: "Plot graph"},
  ];
  
  export default qbank;