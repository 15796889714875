import React , {useState} from "react";







function Question(props) {

    const [isClicked, setClick]= useState(false);
    function handleClick(){
        setClick(!isClicked);  
                  
    }

    const [qnsIsClicked, setqnsClick]= useState(false);
    function handleqnsClick(){
        setqnsClick(!qnsIsClicked);  
                  
    }


    return (
        <p>
            <p className="subheading">{props.subTopic} </p>
            <p></p>
            <button onClick={handleqnsClick} className="btn2">Question</button>
            <p></p>
            {qnsIsClicked && <img src={'images/'+props.qImage+'.jpg'} alt="answer" className="responsive" />}
            <p></p>
            <button onClick={handleClick} className="btn2">Answer</button>
            <p></p>
            {isClicked && <img src={'images/'+props.aImage+'.jpg'} alt="answer" className="responsive" />}
            
        </p>);
    
}

export default Question;