import React from "react";


function Header() {
    return (
        <header>
            <h1 className="heading">A Math Question Bank </h1>
        </header>);
    
}

export default Header;