import React from "react";
import Header from "./Header";
import qbank from "../qbank";
import { v4 as uuidv4 } from "uuid";
import TestButton from "./TestButton";
import YearButton from "./YearButton";
import TopicSection from "./TopicSection";

function createWks(item) {
  return <TestButton key={uuidv4()} topic={item} />;
}

function createYearWks(item) {
  return <YearButton key={uuidv4()} year={item} />;
}

var topic_list = qbank.map((a) => a.topic);

var unique_topic_list = [...new Set(topic_list)];
unique_topic_list.sort();

var year_list = qbank.map((a) => a.year);

var unique_year_list = [...new Set(year_list)];

function App() {
  return (
    <div>
      <Header />

      <h2 className="heading">Topic list</h2>
      {unique_topic_list.map(createWks)}
      <h3>Upper Sec Math And Science Tuition</h3>
      <ul>
        <li>Small Group Lessons (Max of 3 students per group)</li>
        <li>
          Face to face lessons @ Punggol Oasis Terraces: Wed 7pm to 10pm ($30
          per hr)
        </li>
        <li>Online lessons: Mon 4pm to 6pm, Fri 4pm to 6pm ($25 per hr) </li>
        <li>Whatsapp (text only): 8709 four two two one </li>
        <li>Whatsapp (text only): Eight Seven Zero Nine 4221 </li>
      </ul>
      <h2 className="heading">Year list</h2>
      {unique_year_list.map(createYearWks)}
      <h2 className="heading">Sub Topic list</h2>
      <p>
        {unique_topic_list.map((item) => (
          <TopicSection key={uuidv4()} topic={item} />
        ))}
      </p>
    </div>
  );
}

export default App;
